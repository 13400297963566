// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import BasicListView, { Action } from 'components/basic-list';
import { Modal, Form, Select, Popconfirm, Button, Tooltip } from 'antd';
import { hasPermission } from 'components/restriction';
import SpecificationDateEditModal from 'components/form/field/specification-relationship/SpecificationDateEditModal';

// Views
import CreateRecordView from 'views/common/CreateRecordView';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Interfaces
import AppState from 'store/AppState.interface';
import { RecordFormEntity, RecordEntityStatus } from 'types/entities';

// Icons
import {
  DeleteOutlined,
  QuestionCircleOutlined,
  PlusCircleOutlined,
  LoadingOutlined,
  EditOutlined
} from '@ant-design/icons';

// Styles
import './SpecificationCompanySummary.scss';

const API: Api = new Api();

interface Props {
  record: RecordFormEntity;
  client_id?: number;
  bundle: string;
  type: string;
  permissions: any[];
};

interface State {
  companySummaryRecord: any;
  companyTypes: any[];
  companies: any[];
  contracts: any[];
  selectedCompanyType: string | null;
  selectedCompany: number | null;
  selectedContract: number | null;
  showCreateDialog: boolean;
  showEditDialog: boolean;
  editItemId: number | null;
  createType: string | null; // company, contract
  isFetchingCompanies: boolean;
  isFetchingContracts: boolean;
  isFetching: boolean;
  isCreating: boolean;
  deleteConfirmId: any;
};

const { Option } = Select;

class SpacificationCompanySummary extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    companySummaryRecord: null,
    companyTypes: [],
    companies: [],
    contracts: [],
    selectedCompanyType: null,
    selectedCompany: null,
    selectedContract: null,
    showCreateDialog: false,
    showEditDialog: false,
    editItemId: null,
    createType: null,
    isFetchingCompanies: false,
    isFetchingContracts: false,
    isFetching: true,
    isCreating: false,
    deleteConfirmId: null,
  };

  componentDidMount = async () => {
    const { client_id, record, bundle, type } = this.props;

    this.mounted = true;

    try {

      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const companySummaryRecord = await API.get(`client/${client_id}/${bundle}/${type}/${record.id}/company-summary`);
      const companyTypes = await API.get(`client/${client_id}/${bundle}/${type}/${record.id}/company-summary/company-types`);

      this.mounted && this.setState({
        companySummaryRecord: companySummaryRecord,
        companyTypes: companyTypes
      });

    } catch (error) {
      console.error(error);
    } finally {
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    const { client_id, record, bundle, type } = this.props;
    const { selectedCompanyType, selectedCompany } = this.state;

    // Fetch companies
    if (prevState.selectedCompanyType !== selectedCompanyType && !!selectedCompanyType) {
      try {

        await new Promise((resolve) => this.setState({ isFetchingCompanies: true }, () => resolve(null) ));
        const companies = await API.get(`client/${client_id}/${bundle}/${type}/${record.id}/company-summary/companies`, {
          company_type: selectedCompanyType
        });

        this.mounted && this.setState({
          companies: companies
        });

      } catch {
        console.error('Failed to fetch companies');
      } finally {
        this.mounted && this.setState({
          isFetchingCompanies: false,
        });
      }
    }

    // Fetch contracts
    if (prevState.selectedCompany !== selectedCompany && !!selectedCompany) {
      try {

        await new Promise((resolve) => this.setState({ isFetchingContracts: true }, () => resolve(null) ));
        const contracts = await API.get(`client/${client_id}/${bundle}/${type}/${record.id}/company-summary/contracts`, {
          company_id: selectedCompany
        });

        this.mounted && this.setState({
          contracts: contracts
        });

      } catch {
        console.error('Failed to fetch contracts');
      } finally {
        this.mounted && this.setState({
          isFetchingContracts: false,
        });
      }
    }

  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  renderCreateDialog = (hideContract: boolean = false, disableCompanyCreation: boolean = false) => {
    const { client_id, record, bundle, type, permissions } = this.props;
    const {
      companyTypes,
      companies,
      contracts,
      selectedCompanyType,
      selectedCompany,
      selectedContract,
      isFetchingCompanies,
      isFetchingContracts,
      isCreating,
    } = this.state;
    return (
      <Modal
        visible
        centered
        zIndex={ 9 }
        title={ 'Link Company' }
        maskClosable={ !isCreating }
        okText={ 'Link' }
        onOk={ () => this.mounted && this.setState({
            isCreating: true,
          }, async () => {
            try {

              const company = companies.find((company: any) => company.id === selectedCompany);
              const companyType = !!company ? _.upperFirst(_.toLower(company.type)) : 'Company';
              let contract_id = selectedContract;

              if (hideContract) {
                contract_id = contracts[0].id;
              }

              await API.put(`client/${client_id}/${bundle}/${type}/${record.id}/company-summary/link-contract`, {
                contract_id: contract_id
              });
              const companySummaryRecord = await API.get(`client/${client_id}/${bundle}/${type}/${record.id}/company-summary`);

              this.setState({
                companySummaryRecord: companySummaryRecord
              }, () => {
                Notification('success', `The selected company has been added to the specification`, `${companyType} Linked`);
              });

            } catch (error) {
              Notification('error', 'Failed to link Company');
            } finally {
              this.mounted && this.setState({
                isCreating: false,
                showCreateDialog: false,
                selectedCompanyType: null,
                selectedCompany: null,
                selectedContract: null,
              });
            }
          }
        ) }
        onCancel={ () => this.setState({
          showCreateDialog: false,
          selectedCompanyType: null,
          selectedCompany: null,
          selectedContract: null,
        }) }
        okButtonProps={{
          loading: isCreating || isFetchingCompanies || isFetchingContracts,
          disabled: hideContract ? (!selectedCompanyType || !selectedCompany || _.isEmpty(contracts)) : (!selectedCompanyType || !selectedCompany || !selectedContract)
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Company Type" required>
            <Select
              placeholder={ 'Please Select' }
              onChange={(companytype: string) => {
                this.setState({
                  selectedCompanyType: companytype,
                  selectedCompany: null,
                  selectedContract: null,
                  companies: [],
                  contracts: [],
                });
              } }
            >
              { companyTypes.map((company: any, index: number) => (
                <Option
                  key={ index }
                  value={ company.type }
                >
                  { company.label }
                </Option>
              ) ) }
            </Select>
          </Form.Item>
          <Form.Item
            required
            label={ (
              <div className="d-f jc-sb w-100p">
                <div>
                  <span>Company</span>
                </div>
                <div>
                  { !selectedCompanyType ? (
                      <PlusCircleOutlined className="fsz-def text-ant-disabled cur-na" />
                    ) : (
                      hasPermission(permissions, `company_${_.kebabCase(selectedCompanyType)}_create`) && !disableCompanyCreation ? (
                        isFetchingCompanies ? (
                          <LoadingOutlined className="fsz-def text-ant-default" />
                        ) : (
                          <PlusCircleOutlined
                            className="fsz-def text-ant-default"
                            onClick={ () => {
                              this.setState({
                                isFetchingCompanies: true,
                                createType: 'company',
                              });
                            } }
                          />
                        )
                      ) : (
                        <Tooltip placement={ 'top' } title={ "You don't have permissions to create companies" }>
                          <PlusCircleOutlined className="fsz-def text-ant-disabled cur-na" />
                        </Tooltip>
                      )
                    )
                  }
                </div>
              </div>
            ) }
          >
            <Select
              showSearch
              loading={ isFetchingCompanies }
              disabled={ isFetchingCompanies || !selectedCompanyType }
              placeholder={ 'Please Select' }
              value={ selectedCompany || undefined }
              onChange={(company_id: number) => {
                this.setState({
                  selectedCompany: company_id,
                  selectedContract: null,
                  contracts: [],
                });
              } }
              filterOption={ (input: any, option: any) => {
                return companies.find((company: any) => company.title.toLowerCase() === option.children.toLowerCase() && company.title.toLowerCase().includes(input.toLowerCase()) );
              }}
            >
              { companies.map((company: any, index: number) => (
                <Option
                  key={ index }
                  value={ company.id }
                >
                  { company.title }
                </Option>
              ) ) }
            </Select>
          </Form.Item>
          { !hideContract &&
            <Form.Item
              required
              label={ (
                <div className="d-f jc-sb w-100p">
                  <div>
                    <span>Contract</span>
                  </div>
                  <div>
                    { !hasPermission(permissions, 'record_service_specification_manage_contracts') ? (
                      <Tooltip placement={ 'top' } title={ "You don't have permissions to create contracts" }>
                        <PlusCircleOutlined className="fsz-def text-ant-disabled cur-na" />
                      </Tooltip>
                    ) : (
                      isFetchingContracts ? (
                        <LoadingOutlined className="fsz-def text-ant-default" />
                      ) : (
                        <PlusCircleOutlined
                          className={ classNames('fsz-def text-ant-default', {
                            'text-ant-disabled': !selectedCompany,
                            'cur-na': !selectedCompany,
                          }) }
                          onClick={ () => {
                            if (!!selectedCompany) {
                              this.setState({
                                isFetchingContracts: true,
                                createType: 'contract',
                              });
                            }
                          } }
                        />
                      )
                    ) }
                  </div>
                </div>
              ) }
            >
              <Select
                showSearch
                loading={ isFetchingCompanies || isFetchingContracts }
                disabled={ isFetchingCompanies || isFetchingContracts || !selectedCompanyType || !selectedCompany }
                placeholder={ 'Please Select' }
                value={ selectedContract || undefined }
                onChange={(contract_id: number) => {
                  this.setState({
                    selectedContract: contract_id
                  });
                } }
                filterOption={ (input: any, option: any) => {
                  return contracts.find((contact: any) => contact.title.toLowerCase() === option.children.toLowerCase() && contact.title.toLowerCase().includes(input.toLowerCase()) );
                }}
              >
                { contracts.map((contract: any, index: number) => (
                  <Option
                    key={ index }
                    value={ contract.id }
                  >
                    { contract.title }
                  </Option>
                ) ) }
              </Select>
            </Form.Item>
          }
        </Form>
      </Modal>
    );
  };

  generateActions = (disabled: boolean | string[]): Action[] => {
    if (_.isArray(disabled)) {
      return [
        {
          node: (
            <Tooltip title={ disabled.join(' ') } placement={ 'left' }>
              <Button disabled>Add Company</Button>
            </Tooltip>
          )
        }
      ];
    }

    if (disabled) {
      return [
        {
          node: <Button disabled>Add Company</Button>
        }
      ];
    }

    return [
      {
        node: <Button onClick={ () => this.setState({ showCreateDialog: true }) }>Add Company</Button>
      }
    ];
  };

  render = () => {
    const { client_id, permissions, record } = this.props;
    const { companySummaryRecord, isFetching, showCreateDialog, showEditDialog, editItemId, createType, selectedCompanyType, selectedCompany, companies, companyTypes } = this.state;

    if (!client_id) return <></>;

    let bundle = this.props.bundle;
    let type = this.props.type;
    let parent_id: any = null;
    let parent_type: any = null;
    let parent_bundle: any = null;

    const hideContract = (selectedCompanyType && companyTypes.some((types: any) => types.type === selectedCompanyType && !!types.hide_contracts)) || false;
    const disableCompanyCreation = (selectedCompanyType && companyTypes.some((types: any) => types.type === selectedCompanyType && !!types.disable_company_creation)) || false;

    if (createType) {
      switch (createType) {
        case 'company':
          const companyType = companyTypes.find((type: any) => type.type === selectedCompanyType);
          if (!!companyType) {
            type = _.kebabCase(companyType.type);
            bundle = _.kebabCase(companyType.bundle);
          }
        break;
        case 'contract':
          const company = companies.find((company: any) => company.id === selectedCompany);
          if (!!company) {
            bundle = 'record';
            type = 'contract';
            parent_id = company.id;
            parent_type = _.kebabCase(company.type);
            parent_bundle = _.kebabCase(company.bundle);
          }
        break;
      }
    }

    const isDisabled = () => {
      if (record.status === RecordEntityStatus.Archived) {
        return true;
      }

      if (!hasPermission(permissions, 'record_service_specification_manage_contracts')) {
        return ["You must have permission to manage contracts to use this feature"];
      }

      return false;
    };

    const actions = this.generateActions(isDisabled());

    const extraColumns = [
      {
        key: 'actions',
        title: '',
        align: 'right',
        width: 100,
        render: (row: any) => {
          return (
            <>
              <Button
                style={{
                  marginLeft: '5px',
                  padding: '4px 7px',
                  width: '32px',
                }}
                disabled={ record.status === RecordEntityStatus.Archived }
                onClick={ () => {
                  this.setState( {
                    showEditDialog: true,
                    editItemId: row.id
                  } );
                } }
              >
                <EditOutlined />
              </Button>
              <Popconfirm
                title={ 'Are you sure?' }
                icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                visible={ this.state.deleteConfirmId === row.key }
                okButtonProps={ {
                  danger: true
                } }
                onConfirm={ () => {
                  this.setState({
                    deleteConfirmId: null,
                    isFetching: true,
                  }, async () => {
                    try {
                      await API.put(`client/${client_id}/${this.props.bundle}/${this.props.type}/${record.id}/company-summary/unlink-contract`, {
                        contract_id: row.id
                      });
                      const companySummaryRecord = await API.get(`client/${client_id}/${this.props.bundle}/${this.props.type}/${record.id}/company-summary`);

                      this.setState({
                        companySummaryRecord: companySummaryRecord
                      }, () => {
                        Notification('success', 'The selected contract has been removed from the specification.', 'Contract Unlinked');
                      });
                    } catch (error) {
                      Notification('error', 'Failed to unlink Company');
                    } finally {
                      this.mounted && this.setState({
                        isFetching: false
                      });
                    }
                  });
                } }
                onCancel={ () => this.setState({ deleteConfirmId: null }) }
              >
                <Button
                  style={{
                    marginLeft: '5px',
                    padding: '4px 7px',
                    width: '32px',
                  }}
                  disabled={ record.status === RecordEntityStatus.Archived }
                  onClick={ () => this.setState({ deleteConfirmId: row.key }) }
                >
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
              { showEditDialog && !!editItemId && editItemId === row.id && !!client_id &&
                <SpecificationDateEditModal
                  items={ _.has(companySummaryRecord, 'data') ? companySummaryRecord.data : [] }
                  clientId={ client_id }
                  itemId={ editItemId }
                  type={ type }
                  bundle={ bundle }
                  recordId={ record.id }
                  onSave={ (companySummaryRecordData: any) => this.mounted && this.setState({
                      companySummaryRecord: {
                        ...companySummaryRecord,
                        data: companySummaryRecordData
                      },
                      showEditDialog: false
                    } )
                  }
                  onClose={ () => this.mounted && this.setState({ showEditDialog: false, editItemId: null }) }
                />
              }
             </>
          );
        },
      }
    ];

    return (
      <BlockingSpinner isLoading={ isFetching } style={{ minHeight: 200 }}>
        <BasicListView
          rightActions={ actions }
          columns={ _.has(companySummaryRecord, 'columns') ? companySummaryRecord.columns : [] }
          items={ _.has(companySummaryRecord, 'data') ? companySummaryRecord.data : [] }
          extraColumns={ hasPermission(permissions, 'record_service_specification_manage_contracts') ? extraColumns : null }
        />
        { showCreateDialog && this.renderCreateDialog(hideContract, disableCompanyCreation) }
        { createType && !!type && !!bundle &&
          <CreateRecordView
            type={ type }
            entity={ bundle }
            parent_id={ parent_id }
            parent_type={ parent_type }
            parent_bundle={ parent_bundle }
            onClose={ () => this.mounted && this.setState({ createType: null, isFetchingCompanies: false, isFetchingContracts: false }) }
            onCreated={ async (record: any) => {
              try {

                switch (createType) {
                  case 'company':
                    await new Promise((resolve) => this.setState({ isFetchingCompanies: true }, () => resolve(null) ));

                    const companies = await API.get(`client/${client_id}/${this.props.bundle}/${this.props.type}/${this.props.record.id}/company-summary/companies`, {
                      company_type: selectedCompanyType
                    });

                    this.mounted && this.setState({
                      companies: companies,
                      selectedCompany: record.id,
                      isFetchingCompanies: false,
                    });
                  break;
                  case 'contract':
                    await new Promise((resolve) => this.setState({ isFetchingContracts: true }, () => resolve(null) ));

                    const contracts = await API.get(`client/${client_id}/${this.props.bundle}/${this.props.type}/${this.props.record.id}/company-summary/contracts`, {
                      company_id: selectedCompany
                    });

                    this.mounted && this.setState({
                      contracts: contracts,
                      selectedContract: record.id,
                      isFetchingContracts: false
                    });
                  break;
                }

              } catch (error) {
                console.error(error);
              } finally {
                this.setState({
                  createType: null
                });
              }
            } }
          />
        }
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
  };
};

export default connect(mapStateToProps, null)(SpacificationCompanySummary);
