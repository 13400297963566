// LIbs
import * as React from 'react';
import { isEqual, cloneDeep, isEmpty, has, isNil, isNull } from 'lodash';
import { Checkbox, Modal } from 'antd';
import _ from 'lodash';

// Components
import InfoBox from 'components/form/info-box';
import TabView from 'components/tab-view';
import Builder from 'components/form/builder';
import { RestrictionWrapper } from 'components/restriction';
import { WorkflowPane } from "components/workflow";
import ExternalControls from "components/form/external-controls";
import InputComponent from 'components/input';
import Badge, { BadgeType } from 'components/badge';

// Services
import Notification from 'services/notification';
import { getNumberFormatProps } from 'services/settings';

// Utils
import { isBlank } from 'utils/utils';
import history from 'utils/history';

// Interfaces
import { RecordFormEntity, RecordEntityStatus } from 'types/entities';
import { Workflow, WorkflowPreview, WorkflowTransition, WorkflowCondition } from 'components/workflow/Workflow.interface';
import { Action as DropdownAction } from 'components/dropdown';
import {
  FieldRule,
  FormRecord,
  ErrorTabState,
  FormErrorResponse,
  FormGroup,
  FormElement,
  FormField,
  FormFieldError,
  ErrorGroupState,
  ErrorElementState,
  ErrorFieldState,
  RenderCloneModal,
  RuleType,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
  APIFieldErrorMessage,
  OnClone,
} from './FormWrapper.interface';

interface Props {
  clientId: number;
  entity?: string;
  record: RecordFormEntity;
  hash?: string;
  pathname?: string;
  isNew?: boolean;
  canCreate: boolean;
  canEdit: boolean;
  canView: boolean;
  canVersion?: boolean;
  canArchive?: boolean;
  canHide?: boolean;
  canRollOutSpecification?: boolean;
  canClone?: boolean;
  canExport?: boolean;
  recordExists?: boolean;
  isLockedTitle?: boolean;
  floatingControls?: boolean | undefined;
  renderCloneDialog?: RenderCloneModal;
  pure?: boolean;
  verboseTabs?: boolean;
  onSave?(
    form: RecordFormEntity,
    callback: (response: FormErrorResponse | FormRecord[]) => void
  ): void;
  onCreate?(
    form: RecordFormEntity,
    callback: (response: FormErrorResponse | FormRecord[]) => void
  ): void;
  onChange?(
    form: RecordFormEntity
  ): void;
  onArchive?(
    record_id: number,
    callback: () => void,
  ): void;
  onHide?(
    record_id: number,
    callback: () => void,
  ): void;
  onTransition?(
    record_id: number,
    workflow_id: number,
    transition_id: number,
    requires_comment: boolean,
    callback: () => void,
  ): void;
  onVersioning?(
    record_id: number
  ): void;
  onSpecificationRollOut?(
    record_id: number,
    callback: () => void,
  ): void;
  onClone?: OnClone;
  onExport?(
    recordId: number,
  ): void;
  getRecord?: (silent?: boolean) => void;
};

interface State {
  apiErrors: Record<string, APIFieldErrorMessage>;
  originalRecord: RecordFormEntity;
  modifiedRecord: RecordFormEntity;
  fieldModifiedMessages: Record<string, FormFieldInfoBoxModifiedMessage>;
  fieldErrorMessages: Record<string, FormFieldInfoBoxErrorMessage>;
  cloneOptions: Partial<{ documents: boolean, comments: boolean, resources: boolean }>,
  hasErrors: boolean;
  isModified: boolean;
  isSaving: boolean;
  isCreating: boolean;
  isTransitioning: boolean;
  isVersioning: boolean;
  isLockedTitle: boolean;
  showInfoBox: boolean;
  showArchiveDialog: boolean;
  showHideDialog: boolean;
  showSpecificationRollOutDialog: boolean;
  showCloneDialog: boolean;
  isArchiving: boolean;
  isHiding: boolean;
  isSpecificationRollingOut: boolean;
  isCloning: boolean;
};

export function getColSpanClass(col: number = 1): string {
  const baseClass = 'Form-Grid-Item';
  switch (col) {
    case 1:
      return `${baseClass}--one-col`;

    case 2:
      return `${baseClass}--two-col`;

    case 3:
      return `${baseClass}--three-col`;

    case 4:
      return `${baseClass}--four-col`;

    case 5:
      return `${baseClass}--five-col`;

    case 6:
      return `${baseClass}--six-col`;

    case 7:
      return `${baseClass}--seven-col`;

    case 8:
      return `${baseClass}--eight-col`;

    case 9:
      return `${baseClass}--nine-col`;

    case 10:
      return `${baseClass}--ten-col`;

    case 11:
      return `${baseClass}--eleven-col`;

    case 12:
      return `${baseClass}--twelve-col`;

    default:
      return '';
  }
}

class FormWrapper extends React.Component<Props, State> {

  tabViewRef: any = React.createRef();
  mounted: boolean = false;

  state: State = {
    apiErrors: {},
    modifiedRecord: cloneDeep(this.props.record),
    originalRecord: cloneDeep(this.props.record),
    fieldModifiedMessages: {},
    fieldErrorMessages: {},
    cloneOptions: {},
    hasErrors: false,
    isLockedTitle: this.props.isLockedTitle || false,
    isModified: false,
    isSaving: false,
    isCreating: false,
    isTransitioning: false,
    isVersioning: false,
    showInfoBox: false,
    showArchiveDialog: false,
    showHideDialog: false,
    showSpecificationRollOutDialog: false,
    showCloneDialog: false,
    isArchiving: false,
    isHiding: false,
    isSpecificationRollingOut: false,
    isCloning: false,
  };

  componentDidMount = () => {
    this.mounted = true;
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!isEqual(this.props.record, prevProps.record)) {
      this.setState({
        originalRecord: cloneDeep(this.props.record),
        modifiedRecord: cloneDeep(this.props.record),
        isModified: false,
      });
    }

    // Redirect to first tab if no hash found
    if (this.props.verboseTabs && !!this.props.pathname && !this.props.hash) {
        history.replace({ pathname: this.props.pathname, hash: `${_.snakeCase(this.state.modifiedRecord.form[0].title).toLowerCase()}` });
    }

    if (!!this.props.onChange && !isEqual(this.state.modifiedRecord, prevState.modifiedRecord)) {
      this.props.onChange(this.state.modifiedRecord);
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  setFieldModifiedMessage = (id: string, message?: FormFieldInfoBoxModifiedMessage) => {
    const { isNew } = this.props;

    // If message is set we set the message if not we remove it from the object
    if (message) {
      this.setState((state) => ({
        isModified: true,
        fieldModifiedMessages: {
          ...state.fieldModifiedMessages,
          [id]: message
        }
      }));
    } else if (this.state.fieldModifiedMessages[id]) {
      this.setState((state: State) => {
        const { fieldModifiedMessages } = state;
        delete fieldModifiedMessages[id];
        const isModified = isNew ? false : !isEmpty(fieldModifiedMessages);

        return {
          isModified: isModified,
          fieldModifiedMessages: {
            ...fieldModifiedMessages,
          }
        };
      });
    }
  };

  setFieldErrorMessage = (id: string, message?: FormFieldInfoBoxErrorMessage) => {
    // If message is set we set the message if not we remove it from the object
    if (message) {
      this.setState((state) => {
        const updatedFieldErrorMessages = {
          ...state.fieldErrorMessages,
          [id]: message
        };

        const errorMessages = Object.values(updatedFieldErrorMessages);
        const hasErrors = errorMessages.find((entry) => !entry.isHidden);

        return ({
          hasErrors: !!hasErrors,
          fieldErrorMessages: updatedFieldErrorMessages
        });
      });
    } else if (this.state.fieldErrorMessages[id]) {
      this.setState((state: State) => {
        const { fieldErrorMessages } = state;
        delete fieldErrorMessages[id];
        const errorMessages = Object.values(fieldErrorMessages);
        const hasErrors = errorMessages.find((entry) => !entry.isHidden);

        return {
          hasErrors: !!hasErrors,
          fieldErrorMessages: {
            ...fieldErrorMessages,
          }
        };
      });
    }
  };

  getFieldLabel = (field: FormField, column: string, hasMultipleColumns: boolean): string => {
    if (hasMultipleColumns && field.columns[column]) {
      return `${field.label} - ${field.columns[column].label}`;
    }

    return field.label;
  };

  getSavePermissionsErrorMessage = () => {
    const { canCreate, canEdit, recordExists } = this.props;

    if (recordExists && canEdit === false) {
      return 'You do not have permission to edit this record';
    } else if (canCreate === false) {
      return 'You do not have permission to create a record';
    }

    return 'Unknown';
  };

  formTabHasErrors = (error: ErrorTabState) => {
    let hasErrors = false;
    const groups = error.groups || [];
    groups.forEach((group: ErrorGroupState) => {
      group.elements.forEach((element: ErrorElementState | null) => {
        if (element?.field) {
          element.field.forEach((error: ErrorFieldState | undefined) => {
            if (error) {
              const entries: [string, FormFieldError][] = Object.entries(error);
              entries.forEach(([key, entry]) => {
                if (entry.error.length > 0) {
                  hasErrors = true;
                }
              });
            }
          });
        }
      });
    });

    return hasErrors;
  };

  formHasErrors = (errors: ErrorTabState[]): boolean => {
    let hasErrors = false;

    errors.forEach((error: ErrorTabState) => {
      const groupHasErrors = this.formTabHasErrors(error);

      if (groupHasErrors) {
        hasErrors = groupHasErrors;
      }
    });

    return hasErrors;
  };

  getFormStatus = (modifiedRecord: RecordFormEntity, originalRecord: RecordFormEntity, errors: ErrorTabState[]): { hasErrors: boolean; isModified: boolean } => {
    return {
      hasErrors: this.formHasErrors(errors),
      isModified: !isEqual(modifiedRecord, originalRecord),
    };
  };

  updateFormRecord = (formRecord: FormRecord, formTab: number) => {
    const { modifiedRecord } = this.state;

    modifiedRecord.form[formTab] = formRecord;

    this.setState({
      originalRecord: _.cloneDeep(modifiedRecord),
      modifiedRecord: _.cloneDeep(modifiedRecord),
    });
  };

  updateModifiedRecord = (record: RecordFormEntity) => {
    this.setState({
      modifiedRecord: _.cloneDeep(record),
    });
  };

  getFormData = (formRecord: FormRecord, formTab: number, title?: string, isLockedTitle?: boolean, callback?: () => void) => {
    const newModifiedRecord = _.cloneDeep(this.state.modifiedRecord);
    newModifiedRecord.form[formTab] = formRecord;

    if (title) {
      newModifiedRecord.title = title;
    }

    this.setState({
      modifiedRecord: newModifiedRecord,
      isLockedTitle: isLockedTitle !== undefined ? isLockedTitle : this.state.isLockedTitle
    }, () => {
      callback && callback();
    });

    return newModifiedRecord;
  };

  validate = (field: FormField, column: string, value: string | number | undefined | null): string[] => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const rules = field.rules && field.rules[column] ? field.rules[column] : [];
    const errors: string[] = [];

    rules.forEach((rule: FieldRule) => {
      switch (rule.type) {
        case RuleType.Regex:
          if (isSafari || isBlank(value) || isNil(value)) {
            break;
          }

          // We need to remove the first and last / in the regex so the RegExp function will work correctly. The second in the array is the rule, the 3rd is the flags
          const regexRule: string = `${rule.value}`.substr(
            `${rule.value}`.indexOf('/') + 1,
            `${rule.value}`.lastIndexOf('/') - 1,
          );
          const regexFlag: string | null = `${rule.value}`.substr(
            `${rule.value}`.lastIndexOf('/') + 1,
            `${rule.value}`.length,
          );

          const regex = new RegExp(regexRule, regexFlag);

          const result = regex.test(value.toString());
          if (result === false) {
            errors.push(rule.message);
          }
          break;
        case RuleType.MinVal:
          if (isBlank(value) || isNil(value)) {
            break;
          }

          if (Number(value) < Number(rule.value)) {
            errors.push(rule.message);
          }
          break;
        case RuleType.MaxVal:
          if (isBlank(value) || isNil(value)) {
            break;
          }

          if (Number(value) > Number(rule.value)) {
            errors.push(rule.message);
          }
          break;
        case RuleType.Required:
          if (!!rule.value && (!value || value === ' ')) {
            errors.push(rule.message);
          }
          break;
      }
    });

    return errors;
  };

  scrollToField = (tabId: number, groupId: number, fieldId: string) => {
    const tab = this.state.modifiedRecord.form.find((tab: FormRecord) => tab.id === tabId);
    if (tab) {
      this.tabViewRef.forwardRefChangeTab(tab.title, () => {
        const fieldElement = document.getElementById(`${tabId}|${groupId}|${fieldId}`) as HTMLElement;
        if (fieldElement) {
          fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          // scrollIntoView doesn't have a callback so this is a hack
          // TODO: implement scroll observer when we got time
          setTimeout(() => {
            fieldElement.classList.add('u-animationPulse');
            setTimeout(() => {
              fieldElement.classList.remove('u-animationPulse');
            }, 500);
          }, 500);
        }
      });
    }
  };

  hasRequiredFields = (tab: FormRecord) => {
    let hasRequiredFields = false;

    tab.groups.forEach((group: FormGroup) => {
      group.elements.forEach((element: FormElement) => {
        if (element.data && element.data.config.required) {
          hasRequiredFields = true;
        }
      });
    });

    return hasRequiredFields;
  };

  generateTabs = (tabs: FormRecord[]) => {
    const { recordExists, hash } = this.props;
    const {
      fieldModifiedMessages,
      fieldErrorMessages,
    } = this.state;

    if (isEmpty(tabs)) return {
      activeTab: 'Details',
      processedTabs: [{
        label: 'Details',
        node: <>No Fields Available</>,
      }],
    };

    let activeTab = '';

    const processedTabs = _.isArray(tabs) && tabs.map((tab: FormRecord, index: number) => {
      const errors = Object.values(fieldErrorMessages);
      const modified = Object.values(fieldModifiedMessages);
      const isModified = !isEmpty(modified.find((fieldModified: FormFieldInfoBoxModifiedMessage) => fieldModified.tab === tab.id));
      const hasErrors = !isEmpty(errors.find((fieldError: FormFieldInfoBoxErrorMessage) => (fieldError.tab === tab.id && !fieldError.isHidden)));

      let classes = '';
      if (hasErrors) {
        classes = 'text-danger';
      } else if (isModified && recordExists) {
        classes = 'text-warning';
      }

      if (tab.config.open) {
        activeTab = tab.title;
      }

      // Anchor override
      if (hash) {
        const hashTab = tabs.find((tab: FormRecord) => _.snakeCase(tab.title).toLowerCase() === hash.replaceAll('#', '').toLowerCase());
        if (hashTab) {
          activeTab = hashTab.title;
        }
      }

      return {
        classes,
        label: tab.title,
        preRender: !!tab?.config?.pre_render,
        node: this.renderForm(tab, index),
      };
    });

    return {
      activeTab,
      processedTabs,
    };
  };

  apiErrors = (response: any) => {
    const apiError: Record<string, APIFieldErrorMessage> = {};

    if (response) {
      const apiErrorArray: any = _.has(response, 'errors') ? Object.entries(response.errors) : [];
      apiErrorArray.forEach(([fieldKey, fieldError]: any) => {
        const error = {
          id: fieldKey,
          cardinality: 0,
          tab: fieldError.tab,
          group: fieldError.group
        };

        const fieldErrorValues = Object.entries(fieldError.values);
        fieldErrorValues.forEach(([fieldErrorKey, fieldErrors]: any) => {
          const cardinality = fieldErrorKey.split('_');
          const key = `${fieldKey}_${cardinality[1] || 0}`;

          apiError[key] = {
            ...error,
            cardinality: Number(cardinality[1] || 0),
            errors: fieldErrors
          };
        });
      });
    }

    return {
      errors: apiError,
      hasErrors: !isEmpty(apiError)
    };
  };

  doesFormHaveErrors = () => {
    const { fieldErrorMessages } = this.state;

    if (!isEmpty(fieldErrorMessages)) {
      const errors = Object.entries(fieldErrorMessages);
      const updatedErrors = { ...fieldErrorMessages };

      if (!!errors.find(([key, error]) => !!error.isHidden)) {
        errors.forEach(([key, error]) => updatedErrors[key] = { ...error, isHidden: false });
      }

      return { errors: updatedErrors, hasErrors: true };
    }

    return { errors: {}, hasErrors: false };
  };

  handleSave = () => {
    const { onSave } = this.props;
    const { modifiedRecord } = this.state;

    this.setState({
      isSaving: true,
    });

    onSave && onSave(modifiedRecord, (response: FormErrorResponse | FormRecord[] | any) => {

      // Failed response, stop the process
      if (isNull(response)) return this.setState({ isSaving: false });

      const { errors, hasErrors } = this.apiErrors(response);

      if (!_.isEmpty(errors)) {
        this.setState({
          apiErrors: errors,
          isSaving: false,
          fieldErrorMessages: {},
          fieldModifiedMessages: {},
          hasErrors: hasErrors
        }, () => {
          Notification('error', '', 'Failed to save');
        });
      } else {
        this.setState({
          apiErrors: {},
          originalRecord: cloneDeep(response),
          modifiedRecord: cloneDeep(response),
          fieldErrorMessages: {},
          fieldModifiedMessages: {},
          isModified: false,
          isSaving: false,
        });
      }
    });
  };

  handleCreate = () => {
    const { onCreate } = this.props;
    const { modifiedRecord } = this.state;

    this.setState({
      isCreating: true,
    });

    onCreate && onCreate(modifiedRecord, (response: FormErrorResponse | FormRecord[] | any) => {

      // Failed response, stop the process
      if (isNull(response)) return this.setState({ isCreating: false });

      const { errors, hasErrors } = this.apiErrors(response);
      if (!_.isEmpty(errors)) {
        this.setState({
          apiErrors: errors,
          isCreating: false,
          fieldErrorMessages: {},
          fieldModifiedMessages: {},
          hasErrors: hasErrors
        }, () => {
          Notification('error', '', 'Failed to create');
        });
      } else {
        this.setState({
          apiErrors: {},
          originalRecord: cloneDeep(response),
          modifiedRecord: cloneDeep(response),
          fieldErrorMessages: {},
          fieldModifiedMessages: {},
          isModified: false,
          isCreating: false,
          hasErrors: false,
        });
      }
    });
  };

  handleArchive = () => {
    this.props.onArchive && this.props.onArchive(this.props.record.id, () => {
      this.setState({
        showArchiveDialog: false,
        isArchiving: false,
      });
    });
  };

  handleHide = () => {
    this.props.onHide && this.props.onHide(this.props.record.id, () => {
      this.setState({
        showHideDialog: false,
        isHiding: false,
      });
    });
  };

  handleTransition = (workflow_id: number, transition_id: number, requires_comment: boolean) => {
    const { onTransition, record } = this.props;
    onTransition && this.setState({
        isTransitioning: true
      }, () => {
        onTransition(record.id, workflow_id, transition_id, requires_comment, () => {
          this.setState({
            isTransitioning: false
          });
        });
    });
  };

  handleVersioning = () => {
    const { onVersioning, record } = this.props;
    onVersioning && this.setState({
        isVersioning: true
      }, () => {
        onVersioning(record.id);
    });
  };

  handleSpecificationRollOut = () => {
    this.props.onSpecificationRollOut && this.props.onSpecificationRollOut(this.props.record.id, () => {
      this.setState({
        showSpecificationRollOutDialog: false,
        isSpecificationRollingOut: false,
      });
    });
  };

  handleClone = (cloneOptions: string[]) => {
    this.props.onClone?.(this.props.record.id, cloneOptions, () => {
      this.setState({
        cloneOptions: {},
        showCloneDialog: false,
        isCloning: false,
      });
    });
  };

  handleCloneClose = () => {
    this.setState({
      cloneOptions: {},
      showCloneDialog: false,
      isCloning: false,
    });
  };

  handleCloneLoading = (callback: () => void) => {
    this.setState({
      isCloning: true
    }, () => {
      callback();
    });
  };

  handleExport = () => {
    const { onExport, record } = this.props;
    onExport && onExport(record.id);
  };

  getWorkflowActions = (workflow: Workflow, isTransitioning: boolean, showVersion: boolean) => {
    const { hasErrors, isModified, isVersioning } = this.state;
    const actions: DropdownAction[] = [];
    const currentStage = workflow && has(workflow, 'stages') && workflow.stages.find((stage: any) => !!stage.current);

    if (showVersion) {
      actions.push({
        node: 'Edit (New Version)',
        group: 'Workflow',
        onClick: () => this.handleVersioning(),
        disabled: hasErrors ? ['Error on form'] : isModified ? ['You have unsaved changes'] : false,
        isLoading: isVersioning
      });
    }

    if (currentStage && !!currentStage.transitions) {
      currentStage.transitions.forEach((transition: WorkflowTransition) => {

        // Don't render the automatic transitions
        if (transition.trigger === 'auto') {
          return;
        }
        const unmetConditions: string[] = [];

        let hideTransitionOnFail = false;
        transition.conditions.forEach((condition: WorkflowCondition) => {
          if (!condition.pass) {
            if (!!condition.config?.hide_on_fail) {
              hideTransitionOnFail = true;
            } else {
              unmetConditions.push(condition.description);
            }
          }
        });

        if (hideTransitionOnFail) {
          return;
        }

        actions.push(
          {
            node: transition.title,
            group: 'Workflow',
            onClick: () => this.handleTransition(workflow.id, transition.id, transition.requires_comment),
            disabled: hasErrors ? ['Error on form'] : !_.isEmpty(unmetConditions) ? unmetConditions : isModified ? ['You have unsaved changes'] : false,
            isLoading: isTransitioning
          }
        );
      });
    }

    return actions;
  };

  renderArchiveDialog = () => {
    const { isArchiving } = this.state;
    const { record } = this.props;

    const bundleText = record.bundle === 'category' ? ' category item?' : ` ${record.bundle}?`;
    const nestedText = !!record.config.nestable ? ' Any child items of this category will also be archived.' : '';
    const modalText = 'Are you sure you want to archive this' + bundleText + nestedText;

    return (
      <Modal
        visible
        centered
        maskClosable={ !isArchiving }
        closable={ !isArchiving }
        title={ 'Archive Record' }
        okText={ 'Archive' }
        onOk={() => this.mounted && this.setState({
            isArchiving: true
          }, () => {
            this.handleArchive();
          }
        ) }
        onCancel={() => this.mounted && this.setState({
          showArchiveDialog: false,
        }) }
        okButtonProps={{
          loading: isArchiving,
          danger: true
        }}
        cancelButtonProps={{
          disabled: isArchiving
        }}
      >
        { modalText }
      </Modal>
    );
  };

  renderHideDialog = () => {
    const { isHiding } = this.state;
    const { record } = this.props;

    const bundleText = record.bundle === 'category' ? ' category item?' : ` ${record.bundle}?`;
    const nestedText = !!record.config.nestable ? ' Any child items of this category will also be hidden.' : '';
    const modalText = 'Are you sure you want to hide this' + bundleText + nestedText;

    return (
      <Modal
        visible
        centered
        maskClosable={ !isHiding }
        closable={ !isHiding }
        title={ 'Hide Record' }
        okText={ 'Hide' }
        onOk={() => this.mounted && this.setState({
            isHiding: true
          }, () => {
            this.handleHide();
          }
        ) }
        onCancel={() => this.mounted && this.setState({
          showHideDialog: false,
        }) }
        okButtonProps={{
          loading: isHiding,
          danger: true
        }}
        cancelButtonProps={{
          disabled: isHiding
        }}
      >
        { modalText }
      </Modal>
    );
  };

  renderSpecificationRollOutDialog = () => {
    const { isSpecificationRollingOut } = this.state;
    return (
      <Modal
        visible
        centered
        maskClosable={ !isSpecificationRollingOut }
        closable={ !isSpecificationRollingOut }
        title={ 'Force Roll Out' }
        okText={ 'Roll Out' }
        onOk={() => this.mounted && this.setState({
            isSpecificationRollingOut: true
          }, () => {
            this.handleSpecificationRollOut();
          }
        ) }
        onCancel={() => this.mounted && this.setState({
          showSpecificationRollOutDialog: false,
        }) }
        okButtonProps={{
          loading: isSpecificationRollingOut,
          danger: true
        }}
        cancelButtonProps={{
          disabled: isSpecificationRollingOut
        }}
      >
        <p>You are about to force roll out the saved specification to all Contracts and Service Specifications throughout the system.</p>
        <p className="mT-20" >Are you sure you wish to proceed?</p>
      </Modal>
    );
  };

  renderCloneDialog = () => {
    const { cloneOptions, isCloning } = this.state;
    return (
      <Modal
        visible
        centered
        maskClosable={ !isCloning }
        closable={ !isCloning }
        title={ 'Clone Record' }
        okText={ 'Clone' }
        onOk={ () => this.mounted && this.setState({
            isCloning: true
          }, () => {
            this.handleClone(
              // keys of objects whose data needs to be cloned
              Object.keys(cloneOptions).filter((key: string) => !!cloneOptions[key as keyof typeof cloneOptions])
            );
          }
        ) }
        onCancel={ () => this.mounted && this.setState({ showCloneDialog: false, cloneOptions: {} }) }
        okButtonProps={{
          loading: isCloning
        }}
        cancelButtonProps={{
          disabled: isCloning
        }}
      >
        <p>You are about to clone this record. This will create an entirely new copy of the record using the same name and values.</p>
        <p className="mT-20">Please select the data you wish to copy into this new record:</p>
        <div>
          <Checkbox
            onChange={ (event) => {
              this.mounted && this.setState({ cloneOptions: Object.assign(cloneOptions, { documents: event.target.checked }) });
            } }
            checked={ !!cloneOptions.documents }
          >
            Documents
          </Checkbox>
        </div>
        <div>
          <Checkbox
            onChange={ (event) => {
              this.mounted && this.setState({ cloneOptions: Object.assign(cloneOptions, { comments: event.target.checked }) });
            } }
            checked={ !!cloneOptions.comments }
          >
            Comments
          </Checkbox>
        </div>
        <div>
          <Checkbox
            onChange={ (event) => {
              this.mounted && this.setState({ cloneOptions: Object.assign(cloneOptions, { resources: event.target.checked }) });
            } }
            checked={ !!cloneOptions.resources }
          >
            Resources
          </Checkbox>
        </div>
        <p className="mT-20">Click “Clone” when you are ready to proceed.</p>
      </Modal>
    );
  };

  renderForm = (tab: FormRecord, index: number) => {
    const {
      isNew,
      canCreate,
      canEdit,
      canView,
      record,
      entity,
      clientId,
      getRecord
    } = this.props;

    const {
      apiErrors,
      isSaving,
      originalRecord,
      showInfoBox,
      modifiedRecord,
      fieldModifiedMessages,
      fieldErrorMessages
    } = this.state;

    return (
      <Builder
        clientId={ clientId }
        entity={ entity || 'record' }
        numberFormat={ getNumberFormatProps() }
        apiErrors={ apiErrors }
        canCreate={ isNew || canCreate }
        canEdit={ canEdit }
        canView={ canView }
        config={{ ...tab }} // Modified Form
        record={ modifiedRecord }
        fieldModifiedMessages={ fieldModifiedMessages }
        fieldErrorMessages={ fieldErrorMessages }
        isSaving={ isSaving }
        isNew={ isNew || false }
        returnData={ this.getFormData }
        originalState={{ ...originalRecord.form[index] }} // Original Form
        showInfoBox={ showInfoBox }
        tabIndex={ index }
        validate={ this.validate }
        setFieldModifiedMessage={ this.setFieldModifiedMessage }
        setFieldErrorMessage={ this.setFieldErrorMessage }
        getRecord={ getRecord }
        updateFormRecord={ this.updateFormRecord }
        updateModifiedRecord={ this.updateModifiedRecord }
      />
    );
  };

  changeTitle = (title: string) => {
    const { isModified, originalRecord } = this.state;

    const record: RecordFormEntity = {
      ...this.state.modifiedRecord,
      title: title
    };

    const titleModified = !isEqual(record.title, originalRecord.title);

    this.setState({
      isModified: isModified || titleModified,
      modifiedRecord: record,
    });

    if (titleModified) {
      const message: FormFieldInfoBoxModifiedMessage = {
        id: 'title',
        cardinality: 1,
        group: -1,
        tab: 0,
        order: 0,
        content: {
          label: 'Title',
          from: originalRecord.title,
          to: record.title
        },
        modified: { title: true }
      };

      this.setFieldModifiedMessage('title', message);
    } else {
      this.setFieldModifiedMessage('title');
    }
  };

  // We do this as spaces can have a disproportionate affect on width growth
  autoGrowingTitleWidth = (title: string) => {
    const spacesCount = (title.split(' ').length - 1);
    const specialCharacterCount = title.match(/[@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g)?.length || 0;
    const netCharacterCount = title.length;
    // We reduce the number of spaces because the more spaces the exponentially large the field will grow, we add an extra half a unit for special characters
    const characterCount = (netCharacterCount + (specialCharacterCount / 1.5)) - (spacesCount / 1.25);

    return (characterCount + 1) * 14;
  };

  renderTitle = () => {
    const { isNew, record } = this.props;
    const { modifiedRecord, isLockedTitle } = this.state;

    let extras = <></>;
    let titleField = (
      <InputComponent
        autoFocus={ isNew }
        defaultValue={ modifiedRecord.title }
        style={{ paddingLeft: 0 }}
        onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => this.changeTitle(event.target.value) }
        placeholder="Title"
        isPlain
        required
        isDisabled={ record.status === RecordEntityStatus.Archived }
        shouldAutoGrow
        autoGrow={ (modifiedTitle: string) => this.autoGrowingTitleWidth(modifiedTitle) }
        classNames="fsz-lg fw-600"
      />
    );

    if (isLockedTitle) {
      titleField = <span>{ modifiedRecord.title }</span>;
    }

    if (isNew) {
      return (
        <div className="fsz-lg fw-600">
          { titleField }
        </div>
      );
    }

    if ((record.status === RecordEntityStatus.Archived || record.status === RecordEntityStatus.Hidden) || !!record.version) {
      extras = (
        <div className="mB-20">
          { !!record.version &&
            <span>
              <Badge type={ BadgeType.Disabled } text={ `Version ${record.version}` } />
            </span>
          }
          { record.status === RecordEntityStatus.Hidden &&
            <span className="mL-10">
              <Badge type={ BadgeType.Danger } text={ 'Hidden' } />
            </span>
          }
          { record.status === RecordEntityStatus.Archived &&
            <span className="mL-10">
              <Badge type={ BadgeType.Danger } text={ 'Archived' } />
            </span>
          }
        </div>
      );
    }

    return (
      <>
        { extras }
        <div className="fsz-md fw-500">
          { titleField }
        </div>
      </>
    );
  };

  render = () => {
    const {
      canView,
      record,
      canEdit,
      canCreate,
      canVersion,
      canArchive,
      canHide,
      canRollOutSpecification,
      canClone,
      canExport,
      isNew,
      isLockedTitle,
      onClone,
      floatingControls,
      verboseTabs,
    } = this.props;
    const {
      hasErrors,
      isModified,
      isSaving,
      isCreating,
      isCloning,
      showInfoBox,
      originalRecord,
      modifiedRecord,
      fieldModifiedMessages,
      fieldErrorMessages,
      showArchiveDialog,
      showHideDialog,
      isTransitioning,
      showSpecificationRollOutDialog,
      showCloneDialog,
    } = this.state;

    const { activeTab, processedTabs } = this.generateTabs(modifiedRecord.form);
    const getErrors = this.doesFormHaveErrors();
    const workflow: Workflow | boolean = has(record, 'workflow') && record.workflow;
    const workflowPreview: WorkflowPreview[] | boolean = has(record, 'workflow_preview') && record.workflow_preview;
    const showWorkflow: boolean = has(record, 'workflow.config') && !!record.workflow.config.show_in_form;
    const workflowActions = workflow ? this.getWorkflowActions(workflow, isTransitioning, !!canVersion) : [];

    let actions: DropdownAction[] = [];
    let isValid: boolean | string[] = false;

    if ((canEdit || isModified) && !getErrors.hasErrors) {
      isValid = true;
    }

    if (isNew) {
      if (canCreate && !_.isEmpty(processedTabs)) {
        actions = [
          {
            type: 'primary',
            node: 'Create',
            onClick: () => this.handleCreate(),
            disabled: !isLockedTitle && !modifiedRecord.title ? ['Title Required '] : !isValid,
            isLoading: isCreating,
          }
        ];
      }
    } else {

      actions.push({
        type: 'primary',
        node: 'Save',
        group: 'Action',
        onClick: () => this.handleSave(),
        disabled: !isModified ? ['No unsaved changes'] : !isLockedTitle && !modifiedRecord.title ? ['Title Required '] : !isValid,
        isLoading: isSaving,
      });

      if (!!canRollOutSpecification) {
        actions.push({
          node: 'Force Roll Out',
          group: 'Action',
          onClick: () => this.setState({ showSpecificationRollOutDialog: true }),
          disabled: hasErrors ? ['Error on form'] : isModified ? ['You have unsaved changes'] : false,
        });
      }

      if (record.status !== RecordEntityStatus.Archived) {
        if (!!canArchive) {
          actions.push({
            node: 'Archive',
            group: 'Action',
            onClick: () => this.setState({ showArchiveDialog: true }),
            isDangerous: true,
          });
        }

        if (!!canHide) {
          actions.push({
            node: 'Hide',
            group: 'Action',
            onClick: () => this.setState({ showHideDialog: true }),
            isDangerous: true,
            disabled: hasErrors ? ['Error on form'] : isModified ? ['You have unsaved changes'] : false,
          });
        }
      }

      if (!!canClone) {
        actions.push({
          node: 'Clone',
          group: 'Action',
          onClick: () => this.setState({ showCloneDialog: true }),
          disabled: hasErrors ? ['Error on form'] : isModified ? ['You have unsaved changes'] : false,
        });
      }

      if (!!canExport) {
        actions.push({
          node: 'Export',
          group: 'Action',
          onClick: () => this.handleExport(),
        });
      }

      actions = actions.concat(workflowActions);
    }

    return (
      <RestrictionWrapper restricted={ !canView && 'No access' }>
        <div>
          { !!this.props.pure ? (
            <div>
              { this.renderForm(modifiedRecord.form[0], 0) }
            </div>
          ) : (
            <div>
              { showWorkflow && workflowPreview &&
                <WorkflowPane workflowPreview={ workflowPreview } />
              }
              <div className="bg-tab-grey pT-30 pL-30 pR-30 pB-15 bB-2">
                <div className="d-f jc-sb">
                  <div className="bg-tab-grey">
                    { this.renderTitle() }
                  </div>
                  <ExternalControls
                    fieldErrorMessages={ fieldErrorMessages }
                    hasErrors={ hasErrors || getErrors.hasErrors }
                    isModified={ isModified }
                    isNew={ !!isNew }
                    scrollToField={ this.scrollToField }
                    actions={ actions }
                    floatingControls={ floatingControls }
                  />
                  { showArchiveDialog && this.renderArchiveDialog() }
                  { showHideDialog && this.renderHideDialog() }
                  { showSpecificationRollOutDialog && this.renderSpecificationRollOutDialog() }
                  { showCloneDialog && (!!this.props.renderCloneDialog ? this.props.renderCloneDialog(isCloning, this.handleCloneLoading, onClone, this.handleCloneClose) : this.renderCloneDialog()) }
                </div>
              </div>
              { showInfoBox && (isModified || hasErrors) && (
                <div className="pL-30 pR-30 pB-30 bg-tab-grey">
                  <InfoBox
                    record={ originalRecord.form }
                    fieldErrorMessages={ fieldErrorMessages }
                    fieldModifiedMessages={ fieldModifiedMessages }
                    showModified={ !isNew }
                  />
                </div>
              ) }
              <TabView
                ref={ tabViewRef => this.tabViewRef = tabViewRef }
                defaultActiveTab={ activeTab }
                activeTab={ !!this.props.verboseTabs ? this.props.hash?.replaceAll('#', '') : undefined }
                tabs={ processedTabs || [] }
                onChange={ (tab: string) => {
                  if (verboseTabs) {
                    history.push(`#${_.snakeCase(tab).toLowerCase()}`);
                  }
                } }
              />
            </div>
          ) }
        </div>
      </RestrictionWrapper>
    );

  };
};

export default FormWrapper;
