// Libs
import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Select } from 'antd';
import FieldWrapper from 'components/form/field/field-wrapper';
import CoverModal from 'components/cover-modal';
import PreviewDynamicField from 'components/form/field/dynamic-field-template/PreviewDynamicField';
import DynamicFieldTemplate from 'views/admin/templates/dynamic-field/DynamicFieldTemplate';

// Services
import Notification from 'services/notification';
import { Api } from 'services/api';

// Icons
import { EditOutlined, EyeOutlined } from '@ant-design/icons';

// Interfaces
import { FormField, FormFieldConfig, FormFieldInfoBoxModifiedMessage, FormValues } from 'components/form/form-wrapper';
import { IDynamicFieldTemplate } from 'views/admin/templates/Templates.interfaces';

const COLUMN_KEY = 'dynamic_field_template_select';
const API: Api = new Api();

interface Props {
  config: FormFieldConfig;
  clientId: number;
  field: FormField;
  originalState: FormValues[];
  fieldModifiedMessages: any;
  onChange(
    field: FormField,
    values: Record<string, string | number>[] | [],
    config: FormFieldConfig,
    column?: string,
  ): void;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  border?: boolean;
  canEdit: boolean;
  isDisabled: boolean;
};

interface State {
  template: IDynamicFieldTemplate | null;
  showTemplateEditingModal: boolean;
  showPreviewModal: boolean;
  isLoadingPreview: boolean;
};

class DynamicFieldTemplateSelect extends Component<Props, State> {

  state: State = {
    template: null,
    showTemplateEditingModal: false,
    showPreviewModal: false,
    isLoadingPreview: false,
  };

  componentDidUpdate = (prevProps: Props) => {
    const { clientId, field } = this.props;
    const { template_id, template_type_id } = field.values?.[0] || {};

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(field.values);

      this.getTemplateData(clientId, template_id, template_type_id);
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, originalState, config);

    // Remove validations for this field
    this.validate(originalState, true);
  };

  getTemplateData = async (client_id: number, template_id: string | number, template_type_id: string | number) => {

    if (!template_id) return;

    try {
      await new Promise((resolve) => this.setState({ isLoadingPreview: true }, () => resolve(null)));

      const template = await API.get(`client/${client_id}/admin/templates/dynamic-field/types/${template_type_id}/templates/${template_id}/custom`);

      this.setState({
        template: template,
      });
    } catch (error) {
      Notification('error', 'Failed to fetch template', 'Failed');
    } finally {
      this.setState({ isLoadingPreview: false });
    }
  };

  validate = (values: any, shouldClear = false) => {
    this.generateModifiedState(values, this.props.originalState, shouldClear);
  };

  generateModifiedState = (values: any, originalState: any, shouldClear = false) => {
    const { setFieldModifiedMessage, field, config } = this.props;
    const key = `${field.id}_${config.fieldIndex || 0}_${COLUMN_KEY}`;

    if (!_.isEqual(values, originalState) && !shouldClear) {
      const message = {
        id: field.id,
        cardinality: 0,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {},
      };
      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  renderTemplateEditingModal = (template: any) => {
    return (
      <CoverModal
        style={{ minWidth: '90vw', minHeight: '90vh' }}
        middleContent={ `${template.template_title}` }
        onClose={ () => this.setState({ showTemplateEditingModal: false }) }
      >
        <DynamicFieldTemplate
          template_type_id={ template.template_type_id }
          template_id={ template.template_id }
          preventSetBreadcrumbs
        />
      </CoverModal>
    );
  };

  renderPreviewModal = (client_id: number, template: any) => {
    return (
      <PreviewDynamicField
        clientId={ client_id }
        templateId={ template.template_id }
        templateTypeId={ template.template_type_id }
        onReady={ () => this.setState({ isLoadingPreview: false }) }
        onCancel={ () => this.setState({ showPreviewModal: false }) }
      />
    );
  };

  render = () => {
    const { clientId, field, config, border, onChange, fieldModifiedMessages, canEdit, isDisabled } = this.props;
    const { showTemplateEditingModal, showPreviewModal, isLoadingPreview } = this.state;

    const selectedTemplate = field.values?.[0];
    const key = `${field.id}_${config.fieldIndex || 0}_${COLUMN_KEY}`;
    const isModified = _.has(fieldModifiedMessages, key);
    const rightActions = [];

    if (selectedTemplate) {
      rightActions.push({
        isLoading: isLoadingPreview,
        node: (
          <EyeOutlined
            className={'link fsz-md mT-2 mR-5'}
            onClick={ () => this.setState({ showPreviewModal: true, isLoadingPreview: true }) }
          />
        ),
      });
    }

    if (selectedTemplate && canEdit) {
      rightActions.push({
        node: (
          <EditOutlined
            className={'link fsz-md mT-2'}
            onClick={ () => this.setState({ showTemplateEditingModal: true }) }
          />
        ),
      });
    }

    return (
      <>
        <FieldWrapper
          id={ `${config.tabID}|${config.groupID}|${field.id}` }
          col={ config.fieldColSpan }
          label={ field.label }
          required={ field.config.required }
          errors={ [] }
          border={ border }
          description={ !!field.description && field.description }
          versionChanged={ !!field.config.version_changed }
          rightActions={ rightActions }
        >
          <Select
            showSearch
            placeholder={ field.label }
            className={ classNames('Select-Field', {
              'Select-Field--has-warning': isModified,
            }) }
            disabled={ isDisabled }
            value={ !!selectedTemplate ? +selectedTemplate?.template_id : undefined }
            onChange={ (templateId: number) => {
              const template: any = field.options?.find((option) => option.id === templateId);
              if (template) {
                const newValues = [
                  {
                    template_id: template.id,
                    template_type_id: template.template_type_id,
                    template_reference: template.reference,
                    template_title: template.title,
                  },
                ];
                onChange(field, newValues, config);
              }
            } }
            filterOption={ (input: any, option: any) => {
              return !!field.options?.find((template: any) => template.title.toLowerCase() === option.children.toLowerCase() && template.title.toLowerCase().includes(input.toLowerCase()));
            } }
          >
            { field.options?.map((template: any) => (
              <Select.Option value={ template.id } key={ template.id }>
                { template.title }
              </Select.Option>
            )) }
          </Select>
        </FieldWrapper>
        { showTemplateEditingModal && this.renderTemplateEditingModal(selectedTemplate) }
        { showPreviewModal && selectedTemplate && this.renderPreviewModal(clientId, selectedTemplate) }
      </>
    );
  };
}

export default DynamicFieldTemplateSelect;
